.text-base {
  font-size: 16px;
}
.text-lg {
  font-size: 18px;
}
.text-xl {
  font-size: 20px;
}
.gap-2 {
  gap: 2px;
}
.font-bold {
  font-weight: 700;
}

.text-right {
  text-align: right;
}

.flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}
.items-center {
  align-items: center;
}
.text-grey {
  color: #4a4a4a;
}
.font-medium {
  font-weight: 500;
}

ul {
  margin-bottom: 0px;
}
.text-right {
  text-align: right;
}

.mr-3 {
  margin-right: 0.75rem;
}
.rounded {
  border-radius: 0.25rem;
}
.ml-2 {
  margin-left: 0.25rem;
}
.ml-3 {
  margin-left: 0.75rem;
}
.ml-4 {
  margin-left: 1rem;
}
input[type="file"] {
  display: none;
}
.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  background-color: green;
  color: white;
}
.icons8-google {
  vertical-align: middle;
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iNDgiIGhlaWdodD0iNDgiCnZpZXdCb3g9IjAgMCA0OCA0OCIKc3R5bGU9IiBmaWxsOiMwMDAwMDA7Ij48cGF0aCBmaWxsPSIjRkZDMTA3IiBkPSJNNDMuNjExLDIwLjA4M0g0MlYyMEgyNHY4aDExLjMwM2MtMS42NDksNC42NTctNi4wOCw4LTExLjMwMyw4Yy02LjYyNywwLTEyLTUuMzczLTEyLTEyYzAtNi42MjcsNS4zNzMtMTIsMTItMTJjMy4wNTksMCw1Ljg0MiwxLjE1NCw3Ljk2MSwzLjAzOWw1LjY1Ny01LjY1N0MzNC4wNDYsNi4wNTMsMjkuMjY4LDQsMjQsNEMxMi45NTUsNCw0LDEyLjk1NSw0LDI0YzAsMTEuMDQ1LDguOTU1LDIwLDIwLDIwYzExLjA0NSwwLDIwLTguOTU1LDIwLTIwQzQ0LDIyLjY1OSw0My44NjIsMjEuMzUsNDMuNjExLDIwLjA4M3oiPjwvcGF0aD48cGF0aCBmaWxsPSIjRkYzRDAwIiBkPSJNNi4zMDYsMTQuNjkxbDYuNTcxLDQuODE5QzE0LjY1NSwxNS4xMDgsMTguOTYxLDEyLDI0LDEyYzMuMDU5LDAsNS44NDIsMS4xNTQsNy45NjEsMy4wMzlsNS42NTctNS42NTdDMzQuMDQ2LDYuMDUzLDI5LjI2OCw0LDI0LDRDMTYuMzE4LDQsOS42NTYsOC4zMzcsNi4zMDYsMTQuNjkxeiI+PC9wYXRoPjxwYXRoIGZpbGw9IiM0Q0FGNTAiIGQ9Ik0yNCw0NGM1LjE2NiwwLDkuODYtMS45NzcsMTMuNDA5LTUuMTkybC02LjE5LTUuMjM4QzI5LjIxMSwzNS4wOTEsMjYuNzE1LDM2LDI0LDM2Yy01LjIwMiwwLTkuNjE5LTMuMzE3LTExLjI4My03Ljk0NmwtNi41MjIsNS4wMjVDOS41MDUsMzkuNTU2LDE2LjIyNyw0NCwyNCw0NHoiPjwvcGF0aD48cGF0aCBmaWxsPSIjMTk3NkQyIiBkPSJNNDMuNjExLDIwLjA4M0g0MlYyMEgyNHY4aDExLjMwM2MtMC43OTIsMi4yMzctMi4yMzEsNC4xNjYtNC4wODcsNS41NzFjMC4wMDEtMC4wMDEsMC4wMDItMC4wMDEsMC4wMDMtMC4wMDJsNi4xOSw1LjIzOEMzNi45NzEsMzkuMjA1LDQ0LDM0LDQ0LDI0QzQ0LDIyLjY1OSw0My44NjIsMjEuMzUsNDMuNjExLDIwLjA4M3oiPjwvcGF0aD48L3N2Zz4=")
    50% 50% no-repeat;
  background-size: 100%;
}
.ant-picker {
  padding: 7px 11px 7px !important;
}
.ant-select-selector {
  height: 38px !important;
}
.anticon {
  vertical-align: 0.875em;
}

.box-edit-image:hover .wrapper-edit-image {
  opacity: 0.3;
}

.box-edit-image:hover button {
  opacity: 1;
  pointer-events: all;
}

.box-edit-image .wrapper-edit-image {
  transition: all 0.3s;
  background-color: #000;
  opacity: 0;
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
}

.box-edit-image .button-edit-image {
  background-color: transparent;
  height: 50px;
  width: 50px;
  border-radius: 100%;
  padding: 0px;

  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: calc(50% - 25px);
  left: calc(50% - 25px);
  z-index: 2;
}

.box-edit-image .button-edit-image svg {
  height: 20px;
  width: 20px;
}

.box-edit-image .button-edit-image:hover {
  background-color: #fff;
  border: none;
}
