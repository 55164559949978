.item-card {
    /* width: 8rem; */
    background-color: rgb(250,181,44);
    border: 1px solid#f1f2f6;
    height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 2rem 0.5rem 0.5rem;
    border-radius: 0.3rem;
    margin: 0.5rem;
    position: relative;
  }
.item-card:after {
    content: "\f054";
    font-family: "Font Awesome 5 Pro";
    font-weight: 400;
    position: absolute;
    /* left: 85%; */
    /* top: 31%; */
    /* right: 5%; */
    bottom: 0;
    opacity: 0;

}

.item-card:hover{
    background: rgb(250,181,44);
        transition: all 0.5s;
        border-radius: 10px;
        box-shadow: 0px 6px 15px rgb(250,181,44);
        /* padding: 1.5rem 3.5rem 1.5rem 1.5rem; */
}
.item-card:hover .show_circle{
    display:block !important;
}
.item-card .show_circle{
    display: none!important;
    position: absolute;
    right:0;
    top:-5px;
    color:purple;
    cursor: pointer;
    font-weight: bold;
    
}
  .label { 
    font-size: 0.8rem;
    font-weight: bold;
    color: #2f3542;
    margin-top: 0.4rem;
  }
  
  .drag-drop-list-container{
    padding: 1.5rem 1.2rem;
    display: flex;
    align-items: center;
  }
  
  .material-symbols-outlined {
    color: #ced6e0;
    font-size: 1.3rem;
    position: absolute;
    top:5px;
    right:5px;
    font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 48,
  }
  
  .char-avatar{
    /* width: 2.5rem; */
    max-height: 50rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(245,245,245);
    font-size: 0.9rem;
    font-weight: 600;
    color: #fff;
  }


