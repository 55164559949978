.btn-upload {
    width: 100%;
    background-color: #fff;
    border: 1px dashed #00add4;
    border-radius: 8px;
    color: #00add4;
    padding: 8px 0px;
  }

.edit-css{
    cursor: pointer;
}

@keyframes spin {
  0% {
    rotate: 0deg;
  }
  100% {
    rotate: 360deg;
  }
}

.spinning {
  animation: spin 0.6s ease-in-out infinite;
}

.btn-import {
  background-color: transparent;
  color: #008000;
  border: 1px solid #008000;
  display: flex;
  gap: 3px;
  padding: 7px 10px;
  border-radius: 5px;
  white-space: nowrap;
}

.btn-import:hover {
  background-color: #008000;
  color: white;
  border: 1px solid #008000;
}

.ant-table-thead > tr > th {
  padding: 10px 10px !important;
}

.ant-table-tbody > tr > td {
  padding: 16px 16px !important;
}

.one-line {
  width: 200px; /* Đặt chiều rộng để kiểm soát giới hạn */
  white-space: nowrap;  /* Ngăn nội dung xuống dòng */
  overflow: hidden;      /* Ẩn phần nội dung vượt quá */
  text-overflow: ellipsis; /* Hiển thị dấu "..." khi bị cắt */
}

.wrap-search{
  display: flex;
  flex-wrap: wrap;
  gap: 10px
}

.wrap-search input{
  width: 250px;
}

.wrap-button{
  display: flex;
  gap: 10px;
  margin-left: auto;
}

.wrap-all{
  display: flex;
  gap: 15px
}

.btn-search {
  white-space: nowrap;
}

@media (max-width: 1300px) {
  .wrap-all{
    display: block;
  }

  .wrap-button{
    margin-left: 0;
    padding: 10px 0;
  }
}

@media (max-width: 640px) {
  .wrap-all{
    display: block;
  }

  .wrap-button{
    margin-left: 0;
  }
}
