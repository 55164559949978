.button-excel {
    border: 1px solid green;
    padding: 7px 10px;
    border-radius: 5px;
    color: green;
    background-color: white;
    width: fit-content;
    white-space: nowrap;
}

.button-excel:hover {
    color: white;
    background-color: green;
}

.icon-excel {
    display: inline-block;
}

@keyframes spin {
    0% {
      rotate: 0deg;
    }
    100% {
      rotate: 360deg;
    }
  }
  
  .spinning {
    animation: spin 0.6s ease-in-out infinite;
  }

.wrap-import {
  border: 1px dashed green;
  border-radius: 10px;
  width: 250px;
  
}
.wrap-import:hover {
  border-radius: 10px;
  transition: color;
}

.text-inside {
  text-align: center;
  cursor: pointer;
  color: green;
  width: 100%;
  padding: 10px 0;
}

.text-inside:hover {
  background-color: green;
  border-radius: 10px;
  color: white;
  transition: color;
  height: 100%;
  
}

.text-inside .italic {
  font-style: italic;
  font-weight: 400;
}

.limit-height {
  max-height: 300px;
  min-height: fit-content;
  overflow-y: auto;
}
